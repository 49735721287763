import React, { useState, memo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';
import { useMutation } from '@apollo/client';
import {
  Box,
  Button,
  RadioButtonGroup,
  MaskedInput,
  Heading,
  Text,
} from 'grommet';
import { get, map, trim } from '../../lib/nodash';

import usStates from '../../lib/usStates';
import FieldInputWithErrors from '../Inputs/FieldInputWithErrors';
import { CREATE_CUSTOMER_AND_SOLICIT_MEMBER_DISCOUNT } from '../../queries/industryQueries';
import InputSelect from '../Inputs/InputSelect';
import InputText from '../Inputs/InputText';
import useApplyDiscountCode from '../Cart/useApplyDiscountCode';
import useModalNavigation from '../useModalNavigation';
import { setMemberReferral } from '../../state/industry/industrySlice';

const validationSchema = Yup.object().shape({
  first_name: Yup.string().required('Required'),
  last_name: Yup.string().required('Required'),
  email: Yup.string().email('Invalid email').required('Required Field*'),
  phone: Yup.string()
    .min(14, 'Invalid Phone Number')
    .required('Required Field*'),
  shipping_address1: Yup.string().trim().required('Required Field*'),
  shipping_name: Yup.string(),
  shipping_city: Yup.string().required('Required Fi eld*'),
  shipping_state: Yup.string().required('Required Field*'),
  shipping_zip: Yup.string().required('Required Field*'),
  referred_by_name: Yup.string().required('Required Field*'),
  referred_by_email: Yup.string().required('Required Field*'),
  lead_type: Yup.string().required('Required Field*'),
});

const FormPartnerLeadDiscount = ({
  blok: {
    shopifyTags,
    shopifyNote,
    industryTypes,
    mailingListId,
    memberType = 'industry',
    defaultIndustryType,
    referralInputLabel,
    submitLabel = 'Submit',
    returnTo,
  },
}) => {
  const dispatch = useDispatch();
  const { applyDiscountCode } = useApplyDiscountCode();
  const { navigate } = useModalNavigation();
  const [stateOptions, setStateOptions] = useState(
    usStates.map((x) => x.abbreviation)
  );

  const [createCustomerAndLead] = useMutation(
    CREATE_CUSTOMER_AND_SOLICIT_MEMBER_DISCOUNT
  );

  const handleSearch = (text) => {
    const escapedText = text.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');
    const exp = new RegExp(escapedText, 'i');
    setStateOptions(
      usStates
        .filter((o) => exp.test(o.abbreviation))
        .map((x) => x.abbreviation)
    );
  };

  return (
    <Box fill="horizontal">
      <Formik
        initialValues={{
          first_name: '',
          last_name: '',
          email: '',
          shipping_address1: '',
          shipping_address2: '',
          shipping_city: '',
          shipping_state: '',
          shipping_zip: '',
          phone: '',
          allows_sms_contact: 'Yes',
          shopify_tags: map(trim, shopifyTags.split(',')),
          shopify_note: shopifyNote,
          referred_by_name: '',
          referred_by_email: '',
          lead_type: memberType,
          industry_type: defaultIndustryType,
          mailing_list_id: mailingListId,
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting, setStatus }) => {
          const parsedValues = {
            ...values,
            allows_sms_contact: values.allows_sms_contact === 'Yes',
          };
          setSubmitting(true);
          try {
            const variables = {
              customerInput: {
                email: parsedValues.email,
                attributes: {
                  firstName: parsedValues.first_name,
                  lastName: parsedValues.last_name,
                  phone: parsedValues.phone,
                  tags: parsedValues.shopify_tags,
                  note: parsedValues.shopify_note,
                  addresses: [
                    {
                      address1: parsedValues.shipping_address1,
                      address2: parsedValues.shipping_address2,
                      city: parsedValues.shipping_city,
                      provinceCode: parsedValues.shipping_state,
                      zip: parsedValues.shipping_zip,
                      countryCode: 'US',
                    },
                  ],
                  metafields: [
                    {
                      namespace: 'communications',
                      key: 'allows_sms_contact',
                      valueType: 'STRING',
                      value: parsedValues.allows_sms_contact ? 'true' : 'false',
                    },
                    {
                      namespace: 'industry',
                      key: 'type',
                      valueType: 'STRING',
                      value: parsedValues.industry_type,
                    },
                  ],
                },
              },
              discountInput: {
                name: parsedValues.referred_by_name,
                email: parsedValues.referred_by_email,
                type: parsedValues.lead_type,
                referring_email: parsedValues.email,
                referring_name: `${parsedValues.first_name} ${parsedValues.last_name}`,
                source: 'compass',
              },
              email: parsedValues.email,
              mailing_list_id: parsedValues.mailing_list_id,
            };
            const response = await createCustomerAndLead({
              variables,
            });

            const member = get('data.solicitMemberDiscount', response);
            dispatch(setMemberReferral({ member, solicitation: parsedValues }));
            if (member) {
              await applyDiscountCode(member.referral_code);
            }
            if (returnTo) {
              navigate(returnTo);
            }
            setSubmitting(false);
          } catch (e) {
            console.log('ERROR', e);
            setSubmitting(false);
            setStatus({ error: e });
          }
        }}
      >
        {({ isSubmitting, status, handleSubmit, isValid }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Box gap="medium">
                <Heading level={3}>Compass Referral Form</Heading>
                <Box direction="row-responsive" gap="small">
                  <Box flex>
                    <Field
                      component={FieldInputWithErrors}
                      inputComponent={InputText}
                      label="First Name"
                      type="text"
                      name="first_name"
                    />
                  </Box>
                  <Box flex>
                    <Field
                      component={FieldInputWithErrors}
                      inputComponent={InputText}
                      label="Last Name"
                      type="text"
                      name="last_name"
                    />
                  </Box>
                </Box>
                <Box direction="row-responsive" gap="small">
                  <Box flex>
                    <Field
                      component={FieldInputWithErrors}
                      inputComponent={InputText}
                      label="Email Address"
                      type="email"
                      name="email"
                    />
                  </Box>
                  <Box flex>
                    <Field
                      component={FieldInputWithErrors}
                      inputComponent={MaskedInput}
                      mask={[
                        { fixed: '(' },
                        {
                          length: 3,
                          regexp: /^[0-9]{1,3}$/,
                          placeholder: 'xxx',
                        },
                        { fixed: ')' },
                        { fixed: ' ' },
                        {
                          length: 3,
                          regexp: /^[0-9]{1,3}$/,
                          placeholder: 'xxx',
                        },
                        { fixed: '-' },
                        {
                          length: 4,
                          regexp: /^[0-9]{1,4}$/,
                          placeholder: 'xxxx',
                        },
                      ]}
                      label="Phone"
                      type="tel"
                      name="phone"
                    />
                  </Box>
                </Box>
                <Box direction="row-responsive" gap="small">
                  <Box flex>
                    <Field
                      component={FieldInputWithErrors}
                      inputComponent={InputText}
                      label="Shipping Address"
                      type="text"
                      name="shipping_address1"
                    />
                  </Box>
                  <Box flex>
                    <Field
                      component={FieldInputWithErrors}
                      inputComponent={InputText}
                      label="Shipping Address 2"
                      type="text"
                      name="shipping_address2"
                    />
                  </Box>
                </Box>
                <Box direction="row-responsive" gap="small">
                  <Box flex>
                    <Field
                      component={FieldInputWithErrors}
                      inputComponent={InputText}
                      label="City"
                      type="text"
                      name="shipping_city"
                    />
                  </Box>
                  <Box flex>
                    <Field
                      label="State"
                      name="shipping_state"
                      options={stateOptions}
                      onSearch={handleSearch}
                      component={FieldInputWithErrors}
                      inputComponent={InputSelect}
                    />
                  </Box>
                  <Box flex>
                    <Field
                      component={FieldInputWithErrors}
                      inputComponent={InputText}
                      label="Zip Code"
                      type="text"
                      name="shipping_zip"
                    />
                  </Box>
                </Box>
                <Box direction="row-responsive" gap="small">
                  <Box flex>
                    <Field
                      name="allows_sms_contact"
                      component={FieldInputWithErrors}
                      inputComponent={RadioButtonGroup}
                      options={['Yes', 'No']}
                      direction="row"
                      fill="vertical"
                      label="Can we contact you via SMS?"
                    />
                  </Box>
                </Box>
                <Box>
                  <Box flex>
                    <Text weight={400}>{referralInputLabel}</Text>
                    <Box direction="row-responsive" gap="small">
                      <Field
                        name="referred_by_name"
                        component={FieldInputWithErrors}
                        placeholder="Name"
                      />
                      <Field
                        name="referred_by_email"
                        component={FieldInputWithErrors}
                        type="email"
                        placeholder="Email"
                      />
                    </Box>
                  </Box>
                </Box>
                <Box>
                  <Box flex>
                    <Field
                      name="industry_type"
                      component={FieldInputWithErrors}
                      inputComponent={RadioButtonGroup}
                      options={[...industryTypes, 'Both']}
                      direction="row"
                      fill="vertical"
                      label="I am primarily a..."
                    />
                  </Box>
                </Box>
                <Box className="actions">
                  <Field type="hidden" name="shopify_tags" />
                  <Field type="hidden" name="shopify_note" />
                  <Field type="hidden" name="mailing_list_id" />
                  {status && status.error && (
                    <Box
                      className="form-errors"
                      margin={{ vertical: 'medium' }}
                    >
                      <Text color="status-error">
                        We&apos;re sorry, something went wrong.
                      </Text>
                    </Box>
                  )}
                  <Button
                    style={{ height: '61px' }}
                    primary
                    type="submit"
                    label={submitLabel}
                    onClick={handleSubmit}
                    disabled={isSubmitting || !isValid}
                  />
                </Box>
              </Box>
            </form>
          );
        }}
      </Formik>
    </Box>
  );
};

FormPartnerLeadDiscount.propTypes = {
  blok: PropTypes.shape({
    shopifyTags: PropTypes.string,
    shopifyNote: PropTypes.string,
    industryTypes: PropTypes.array.isRequired,
    mailingListId: PropTypes.string,
    memberType: PropTypes.oneOf([
      'industry',
      'stockist',
      'influencer',
      'painting_partner',
    ]),
    defaultIndustryType: PropTypes.string.isRequired,
    referralInputLabel: PropTypes.string.isRequired,
    submitLabel: PropTypes.string,
    returnTo: PropTypes.object.isRequired,
  }),
};

export default memo(FormPartnerLeadDiscount);
